import React from "react";
import { BoxWrapper } from "../../../../assets/css/Custom/AcademicStyle";
import NoticeDeanOffice from "./NoticeDeanOffice";
import getData from "../../Common/getData";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DeanCard from "./DeanCard";

const DeanIROffice = () => {
  const DeanOffice = "Industry and Alumni Relations";

  const [Deans] = getData("/people/deans?type=dean");

  const deanData = Deans ? Deans.find(d => d.committee.toLowerCase().includes(DeanOffice.toLowerCase())) : null;

  const [associateDeans] = getData("/people/deans?type=associatedean");

  const filteredAssociateDeans = associateDeans ? associateDeans.filter(ad => ad.committee.toLowerCase().includes(DeanOffice.toLowerCase())) : [];

  return (
    <>
      <BoxWrapper>
        <div style={{ margin: "2rem 0" }}>
          {
            deanData &&
            <DeanCard image={deanData.image} title={`${deanData.designation} ${deanData.committee}`} description={deanData.name} />
          }
          <hr />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2rem",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            {filteredAssociateDeans.map((dean, index) => (
              <DeanCard
                key={index}
                image={dean.image}
                title={dean.name}
                description={dean.designation}
                committee={dean.committee}
              />
            ))}
          </div>
          <div>
            <NoticeDeanOffice dean="Industry Relations" />
          </div>
        </div>
      </BoxWrapper>
    </>
  );
};

export default DeanIROffice;

