import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../Home/ResearchSection/ResearchSection.style";
import Heading from "../../../Components/Heading";
import { margin, marginTop } from "styled-system";
import { Typography } from "@mui/material";
import React from "react";

export default function AlumniContent() {
  const { t } = useTranslation();

  const director =
    "https://nitjsr.ac.in/backend/uploads/alumunicorner/add/director.5dd2bd73bde93718a6fd.jpg";
  const deanAlumni =
    "https://nitjsr.ac.in/backend/uploads/Faculty/EE108/profile/d75d622a-0fbb-43b1-b8b8-35a5148cf430.jpg";

  return (
    <div className="wrapper" style={{ margin: "5rem 0rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        <div className="thumbnail text-center  my-1 mx-1">
          <img
            src={director}
            alt="hod"
            id="hodimg"
            style={{
              width: "180px",
              height: "180px",
              borderRadius: "50%",
            }}
          />
          <div className="caption content NameArea">
            <h5
              className="text-center"
              style={{ fontWeight: "600", color: "#274187", marginTop: "1rem" }}
            >
              {t("Director")}
            </h5>
            <p className="text-center">{t("Prof. Gautam Sutradhar")}</p>
          </div>
        </div>
        <div className="thumbnail text-center  my-1 mx-1">
          <img
            src={deanAlumni}
            alt="hod"
            id="hodimg"
            style={{
              width: "180px",
              height: "180px",
              borderRadius: "50%",
            }}
          />
          <div className="caption content NameArea">
            <h5
              className="text-center"
              style={{ fontWeight: "600", color: "#274187", marginTop: "1rem" }}
            >
              {t("d-industry")}
            </h5>
            <p className="text-center">{t("n-industry")}</p>
          </div>
        </div>
      </div>
      <hr />
      <SectionHeader>
        <Heading content={t("Message")} style={{ marginTop: "3rem" }} />
      </SectionHeader>
        <div>
            <Typography sx={{fontSize: "1.25rem", paddingY: ".5rem"}}>
                {t("Alumni_Message_1")}
            </Typography>
            <Typography sx={{fontSize: "1.25rem", paddingY: ".5rem"}}>
                {t("Alumni_Message_2")}
            </Typography>
            <Typography sx={{fontSize: "1.25rem", paddingY: ".5rem"}}>
                {t("Alumni_Message_3")}
            </Typography>
            <Typography sx={{fontSize: "1.25rem", paddingY: ".5rem"}}>
                {t("Alumni_Message_4")}
            </Typography>

            <div
                style={{fontSize: "1.5rem"}}
                className="mt-5 text-secondary">
                <strong>Donate to NIT Jamshedpur :</strong>{" "}
                <a
                    style={{paddingRight: "10px"}}
                    href="https://nitjsr.ac.in/backend/files/SOP_for_Alumni_&_Other_Donations.pdf"
                    rel="noreferrer"
                    target={"_blank"}
                >
                    {"Link"}
                </a>
            </div>

        </div>
    </div>
  );
}
