import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../../../Components/UI/Container";
import Heading from "../../../../../Components/Heading";
import { LongPara } from "../../../styles";
// import { getRequest } from "../../../../../functions/request";
import { Helmet } from "react-helmet";

const Services = () => {
    const { t } = useTranslation();

    return (
        <>
            <Heading
          content={t("central_facilities_computer_center_services_heading")}
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <LongPara
          dangerouslySetInnerHTML={{
            __html: t("central_facilities_computer_center_services"),
          }}
          style={{ textAlign: "justify", fontSize: "1em", lineHeight: "1.6" }}
        />
        </>
    );
};

export default Services;
