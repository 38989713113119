import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from "../../../../../assets/images/ComputerCenter/cc-outside.jpg";
import img2 from "../../../../../assets/images/ComputerCenter/cc-room.jpg";


function Carousel() {
  var settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 6000,
        infiniteLoop: true,
  };
    return (
    <div>
      <Slider {...settings}>
          <div>
            <img src={img1} alt="slider-img" className="small-carousel-img" />
          </div>
          <div>
            <img src={img2} alt="slider-img" className="small-carousel-img" />
          </div>
      </Slider>
    </div>
  );
}


export default Carousel;
