import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../../../Components/UI/Container";
import Heading from "../../../../../Components/Heading";
import { Paper, Typography, Link } from "@mui/material";
import { LongPara, TableContainer, TableWrapper } from "../../../styles";
import { getRequest } from "../../../../../functions/request";
import { Helmet } from "react-helmet";

const ContactUs = () => {

    const {t} = useTranslation();

    return (
      <>
      <Heading
        content={t("central_facilities_computer_center_contact_head")}
        style={{
        fontSize: "1.5em",
        marginTop: "13px",
        fontWeight: "500",
        background: "#274187",
        color: "#fff",
        padding: "1% 1% 1% 3%",
        }}
      />
      <Container>
        <Paper
        style={{
          padding: "25px",
          backgroundColor: "#f5f5f5",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
        }}
        >
        <Typography variant="body1" style={{ marginBottom: "10px" }}>
          {t("central_facilities_computer_center_contact_details_1")}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "10px" }}>
          {t("central_facilities_computer_center_contact_details_2")}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "20px" }}>
          {t("central_facilities_computer_center_contact_details_3")}
        </Typography>
        <Link href="http://helpdesk.nitjsr.ac.in/" target="_blank" rel="noopener" style={{ marginBottom: "5px" }}>
            {t("central_facilities_computer_center_contact_issue_portal_link_text")}
        </Link>
        </Paper>
      </Container>
      </>
    );
};

export default ContactUs;