import avatar from "../../../../assets/images/default.jpg";

function DeanCard ({ image, title, description, committee }) {
    return (
        <div className="thumbnail text-center my-4 mx-4">
            <img
                src={image}
                alt={`${title} image`}
                style={{
                width: "180px",
                height: "180px",
                borderRadius: "50%",
                }}
                fallback={avatar}
            />
            <div className="caption content text-center mx-auto" style={{ maxWidth: "360px" }}>
                <h5
                className="text-center"
                style={{ fontWeight: "600", color: "#274187", marginTop: "1rem" }}
                >
                {title}
                </h5>
                <p className="text-center" style={{ marginBottom: ".25rem" }}>
                {description}
                </p>
                {committee && <p className="text-center">{committee}</p>}
            </div>
        </div>
    );
}

export default DeanCard;
