import React from "react";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";


const StyledAccordion = styled(Accordion)(({ expanded }) => ({
    backgroundColor: "#274187",
    borderRadius: "5px",
    marginBottom: "14px",
    "& .MuiAccordionSummary-root": {
        fontWeight: "bold",
        minHeight: "36px",
        padding: "0 16px",
        "&.Mui-expanded": {
            minHeight: "36px",
        },
    },
    "& .MuiAccordionSummary-content": {
        color: "#fff",
        margin: "0",
        alignItems: "center",
        "&.Mui-expanded": {
            margin: "0",
        },
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
        color: "#fff",
    },
    "&:before": {
        display: "none",
    },
}));


const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: "Poppins",
                    fontWeight: "400",
                },
            },
        },
    },
});


const StyledAccordionDetails = styled(AccordionDetails)(() => ({
    backgroundColor: "#fff",
    color: "#000",
    padding: "16px",
    borderRadius: "0 0 5px 5px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
}));


const FAQs = () => {
    const [expandedPanels, setExpandedPanels] = React.useState([]); // Tracks open panels

    const handleChange = (panel) => (event, isExpanded) => {
        setExpandedPanels((prev) =>
            isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
        );
    };

    const { t } = useTranslation();

    const faqData = [
        {
            ques: "What are the CC recommended antiviruses for preventing PC?",
            ans: "Answer: Please Click Here for detailed information.",
        },
        {
            ques: "How to connect to the news server using mail clients?",
            table: {
                headers: ["Mail Clients", "Configuration Details"],
                rows: [
                    ["Outlook Express", "Click Here"],
                    ["Microsoft Outlook", "Click Here"],
                    ["Mozilla Thunderbird", "Click Here"],
                    ["Pine", "Click Here"],
                ],
                note: "It's suggested to use Mozilla Thunderbird because it's secure.",
            },
        },
        {
            ques: "What is my disk quota in CC?",
            ans: "Answer: For shell account holders, every eligible person will be given 2 GB of disk space.",
        },
        {
            ques: "How to lock my home account?",
            table: {
                headers: [],
                rows: [
                    ["1. ", "Login to any server in CC"],
                    ["2. ", "Open the folder<home>"],
                    ["3. ", "Go back to the group you belong to (for example MSE, EE etc.)."],
                    ["4. ", "Right click the folder of yours"],
                    ["5. ", "Take the <Properties>."],
                    ["6. ", "Remove access to the <Group> and the <Others>."],

                ],
                note: "",
            }
        },
        {
            ques: "What to do if I cannot get the LAN connection?",
            ans: "Answer: First find out where the problem is that is whether it is in your room only or the entire block. For that check the next rooms nearby and the next blocks. If the problem is for the entire block then the switch is not functioning, otherwise the problem is with your room's connection. Then, the complaint has to be raised to the Network Group in CC."
        },
        {
            ques: "What is my IP and how to get it?",
            table: {
                headers: [],
                rows: [
                    ["1. ", "Go to Hostel IP Address"],
                    ["2. ", " Select your Hostel"],
                    ["3. ", "Then, Select your Block."],
                    ["4. ", "Select your Room Number."],
                    ["5. ", "Click on OK. You will get a pop-up having the IP address Details."],
                ],
                note: "",
            }
        },
        {
            ques: "Why does CC filter and restrict access to NIT Jsr net?",
            ans: "NIT Jsr net is an open computing environment: We do not normally restrict access into or out of our network. This reflects both the traditional openness of the academic environment, and our opinion of where security efforts give the best return.",
        },
        {
            ques: "How to connect computer to the LAN?",
            table: {
                headers: [],
                rows: [
                    ["1. ", "Right click Network Neighborhood"],
                    ["2. ", "Click on Properties"],
                    ["3. ", "Click on Configuration"],
                    ["4. ", "Select TCP/IP fast Ethernet"],
                    ["5. ", "Click Properties. Specify IP, Gateway, Subnet Mask and DNS."],
                ],
                note: "",
            }
        },
    ];


    return (
        <ThemeProvider theme={theme}>
            <div>
                <h3
                    style={{
                        fontSize: "1.5em",
                        marginBottom: "20px",
                        marginTop: "13px",
                        fontWeight: "500",
                        background: "#274187",
                        color: "#fff",
                        padding: "1% 1% 1% 3%",
                    }}
                >
                    General FAQ
                </h3>
                <h5 style={{ marginTop: "5px" }}>General Frequent Enquiries</h5>
                <p>
                    Some of the General questions are being listed here in order to help
                    faculties, students, and staff persons for resolving their queries.
                </p>
                <p>
                    Please Click on the below questions for getting corresponding
                    answers.
                </p>

                {faqData.map((item, index) => (
                    <StyledAccordion
                        key={index}
                        expanded={expandedPanels.includes(`panel${index + 1}`)}
                        onChange={handleChange(`panel${index + 1}`)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index + 1}bh-content`}
                            id={`panel${index + 1}bh-header`}
                        >
                            <Typography sx={{ color: "#fff" }}>{item.ques}</Typography>
                        </AccordionSummary>
                        <StyledAccordionDetails>
                            {item.table ? (
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {item.table.headers.map((header, headerIndex) => (
                                                    <TableCell
                                                        key={headerIndex}
                                                        style={{
                                                            fontWeight: "bold",
                                                            backgroundColor: "#f5f5f5",
                                                            fontFamily: "Poppins",
                                                        }}
                                                    >
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {item.table.rows.map((row, rowIndex) => (
                                                <TableRow key={rowIndex}>
                                                    {row.map((cell, cellIndex) => (
                                                        <TableCell key={cellIndex} style={{  fontFamily: "Poppins" }}>
                                                            <Typography>{cell}</Typography>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {item.table.note && (
                                        <Typography style={{ marginTop: "10px", fontStyle: "italic", fontFamily: "Poppins" }}>
                                            Note: {item.table.note}
                                        </Typography>
                                    )}
                                </div>
                            ) : (
                                <Typography>{item.ans}</Typography>
                            )}
                        </StyledAccordionDetails>
                    </StyledAccordion>
                ))}
            </div>
        </ThemeProvider>
    );
};

export default FAQs;
