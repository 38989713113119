import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../../../Components/UI/Container";
import Heading from "../../../../../Components/Heading";
import { Helmet } from "react-helmet";
import avatar from "../../../../../assets/images/default.jpg";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Link,
  Box,
} from "@mui/material";

const People = () => {

  const { t } = useTranslation();

  const cardData = [
    {
      "id": 1,
      "preName": "Dr.",
      "fname": "Koushlendra",
      "lname": "Kumar Singh",
      "designation": "Professor In-Charge",
      "post": "Professor",
      "department": "Computer Science & Engineering (CSE)",
      "phoneNumber": "+91-9102197734",
      "email": "koushlendra.cse@nitjsr.ac.in",
      "profileLink": "https://nitjsr.ac.in/people/profile/CS103",
      "image": "https://nitjsr.ac.in/backend/uploads/Faculty/CS103/profile/272e8df2-a22e-4f5f-875b-5d9149d5f935.jpeg"
    },
    {
      "id": 2,
      "preName": "Dr.",
      "fname": "Subrata",
      "lname": "Dutta",
      "designation": "FI, Networking",
      "post": "Professor",
      "department": "Computer Science & Engineering (CSE)",
      "facility": "cc",
      "phoneNumber": null,
      "email": "pi.network@nitjsr.ac.in",
      "profileLink": "https://www.nitjsr.ac.in/people/profile/CS106",
      "image": "https://www.nitjsr.ac.in/backend/uploads/Faculty/CS106/profile/5fa86fc6-7ea5-4c7b-b017-e810746495e7.png"
    },
    {
      "id": 3,
      "preName": "Mr.",
      "fname": "Tarkeshwar",
      "lname": "Kumar",
      "designation": "Assistant(SG-I)",
      "facility": "cc",
      "phoneNumber": "8235314115",
      "email": "tarkeshwar.cc@nitjsr.ac.in",
    },
    {
      "id": 4,
      "preName": "Mr.",
      "fname": "Shankar Prasad",
      "lname": "Singh",
      "designation": "Technical Assistant",
      "facility": "cc",
      "phoneNumber": "9471364206",
      "email": "sankar.cc@nitjsr.ac.in",
    },
    {
      "id": 5,
      "preName": "Mr.",
      "fname": "Ravi",
      "lname": "Ranjan",
      "designation": "Technical Assistant",
      "facility": "cc",
      "phoneNumber": "7870499520",
      "email": "raviranjan.adm@nitjsr.ac.in",
    },
    {
      "id": 6,
      "preName": "Mr.",
      "fname": "Abhishek",
      "lname": "Yadav",
      "designation": "Technician",
      "facility": "cc",
      "phoneNumber": "6206806395",
      "email": "abhishek.te@nitjsr.ac.in",
    },
    {
      "id": 7,
      "preName": "Mr.",
      "fname": "Banothu",
      "lname": "Suman",
      "designation": "Technician",
      "facility": "cc",
      "phoneNumber": "",
      "email": "",
    },
    {
      "id": 8,
      "preName": "Mr.",
      "fname": "Shankar",
      "lname": "Kumar",
      "designation": "Technician",
      "facility": "cc",
      "phoneNumber": "",
      "email": "",
    },
    {
      "id": 9,
      "preName": "Mr.",
      "fname": "Harsh",
      "lname": "Vardhan",
      "designation": "Technician",
      "facility": "cc",
      "phoneNumber": "",
      "email": "",
    },
  ];

  const updatedCardData = cardData.map((item) => ({
		...item,
    image: (item.image ? item.image : avatar),
  }));

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="One of the attractions of this campus is the OVAL structure - the Computer Centre, which accommodates central computing facilities. There are mainly three types of services viz. computational services, laboratory services and network services. The Centre's services are extensively used by the students, faculty and staff members of the Institute."
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,Director, conferences ,meeting,labs,laboratories,computers,stafflist,facility,conferences,software,hardware,Computer center,services,manthan,mis,server room,networking"
        />
      </Helmet>
      <Container width="1250px">

        <Heading
          content={t("People")}
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />

        <Grid
          container
          sx={{
            marginBottom: "40px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {updatedCardData.map((person) => (
            <Grid item xs={12} md={10} key={person.id}>
              <Card sx={{ marginBottom: "24px" }}>
                <CardContent>
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems="center"
                    justifyContent={"space-around"}
                  >
                    <Box
                      component="img"
                      src={person.image}
                      alt={`${person.fname} ${person.lname}`}
                      sx={{
                        borderRadius: "50%",
                        width: { xs: "100px", md: "150px" },
                        height: { xs: "100px", md: "150px" },
                        marginRight:"12px",
                        marginBottom: { xs: 2, md: 0 },
                        objectFit: "fill",
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontFamily: "Poppins" }}
                      >
                        {person.preName} {person.fname} {person.lname}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ fontFamily: "Poppins" , textAlign: "center"}}
                      >
                        {person.designation}
                      </Typography>
                    </Box>
                  </Box>
                  <hr />
                  <Typography variant="h6" sx={{ fontFamily: "Poppins" }}>
                    Details
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontFamily: "Poppins" }}
                  >
                    {person.post}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontFamily: "Poppins" }}
                  >
                    {person.department}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontFamily: "Poppins" }}
                  >
                    <Box
                      component={"span"}
                      color={"black"}
                      fontWeight="bold"
                      display="inline"
                    >
                      Email:{" "}
                    </Box>
                    {person.email}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontFamily: "Poppins" }}
                  >
                    <Box
                      component={"span"}
                      color={"black"}
                      fontWeight="bold"
                      display="inline"
                    >
                      Ph-No:{" "}
                    </Box>
                    {person.phoneNumber}
                  </Typography>
                  {person.profileLink && (<Link href={person.profileLink}>Profile Link</Link>) }
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
          
      </Container>
    </>
  );
};

export default People;
