import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppBar, Toolbar, Button, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import Configurations from "./Sections/Configurations";
import CCHome from "./Sections/Home";
import ContactUs from "./Sections/ContactUs";
import Downloads from "./Sections/Downloads";
import People from "./Sections/People";
import Services from "./Sections/Services";
import FAQs from "./Sections/FAQs";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#274187",
                    boxShadow: "none",
                    minHeight: "40px",
                    marginTop: "15px",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Poppins",
                    padding: "0px 15px",
                    margin: "0px",
                    borderRadius: "0px",
                    boxSizing: "border-box",
                    "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                    },
                },
            },
        },
    },
});

const ComputerCenter = () => {
    const { t } = useTranslation();
    const [activeComp, setActiveComp] = useState("Home");

    const sections = {
        Home: CCHome,
        People: People,
        Services: Services,
        // Configurations: Configurations,
        // Downloads: Downloads,
        'Contact Us': ContactUs,
        FAQs: FAQs,
    };

    const ActiveSection = sections[activeComp];

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>{t("Computer Center")}</title>
            </Helmet>

            {/* Blue Navbar */}
            <AppBar position="static">
                <Container
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        padding: "0px",
                    }}
                >
                    <Toolbar>
                        {Object.keys(sections).map((section) => (
                            <Button
                                key={section}
                                onClick={() => setActiveComp(section)}
                                sx={{
                                    fontWeight: activeComp === section ? "bold" : "normal",
                                    color: activeComp === section ? "#274187" : "#ffffff",
                                    backgroundColor: activeComp === section ? "#cee2ff" : "transparent",
                                    "&:hover": {
                                        backgroundColor: activeComp === section ? "#cee2ff" : "rgba(255, 255, 255, 0.2)",
                                    },
                                    height: "100%",
                                }}
                            >
                                {section}
                            </Button>
                        ))}
                    </Toolbar>
                </Container>
            </AppBar>

            <Container>
                <ActiveSection />
            </Container>
        </ThemeProvider>
    );
};

export default ComputerCenter;
